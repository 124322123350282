@import '../../variables';

.container {
  background-color: $violet;
  grid-template-columns: 100%;
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-areas: "header"
  "a-99Ggk";
  grid-template-rows: 90px 1fr;

  &.authorized {
    background-color: white;  
  }

  .header {
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-area: header;
  }
  .content {
    grid-area: a-99Ggk;
    max-width: 100%;
  }
}

@media (min-width: 768px)  {
  .container {
    grid-template-rows: 92px 1fr;

    .content {
      // padding: 0 16px;
    }
  }
}

@media (min-width: 1024px) {
  .container {
    .content,
    .header {
      // padding: 0;
    }
  }
}