@import url('https://fonts.cdnfonts.com/css/montserrat');

body {
  margin: 0;  
  font-family: -apple-system, Montserrat,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, #root, #root>div {
  height: 100%
}

.container {
  width: 100%;
  box-sizing: border-box;
  max-width: 100vw;
}
#root {

}
.container_16px {
  padding: 0 16px;
}

@media (min-width: 1024px) {
  .container {
    width: 992px;
    margin: 0 auto;
  }
}

.ReactModal__Body--open {
  overflow: hidden;
}