@import "../../variables";

.button {
    display: block;
  padding: 16px;
  color: #FFF;
  text-align: center;
  font-size: 18px;
  font-weight: 500;border-radius: 50px;
  background-color: $violet;
  cursor: pointer;
  border: none;
  transition: .2s ease opacity;

  &:hover {
    opacity: .8;
  }
  
  &.isWhite {
    color: $violet;
    background-color: white;
    border: 1px solid #64247F;
  }
}