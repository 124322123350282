.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;

  .text {
    color: #000;
    font-size: 24px;
    font-weight: 500;
  }
}