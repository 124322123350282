.container {
  padding: 8px 16px;
  box-sizing: border-box;
  position: relative;

  .icon {
    position: absolute;
    top: 18px;
    left: 32px;
  }

  input {
    padding: 8px 16px 8px 44px;
    width: 100%;
    border-radius: 12px;
    background: #F2F2F2;
    color: #000;
    font-size: 19px;
    font-weight: 500;
    line-height: 28px;
    box-sizing: border-box;

    &,
    &:active,
    &:focus,
    &:focus-visible {
      border: none;
      outline: none;
    }

    &::placeholder {
      color: #42424280;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 500;
    }
  }

  .clearBtn {
    border-radius: 20px;
    background: #F2F2F2;
    padding: 8px 16px;
    margin-top: 16px;
    color: #000;
    font-size: 16px;
    font-weight: 500;
    width: fit-content;
    cursor: pointer;
    transition: .3s ease all;
    
    &.hidden {
      height: 0;
      padding: 0;
      margin: 0;
      opacity: 0;
      visibility: hidden;
    }
  }
}