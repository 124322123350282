.container {
  height: 100%;

  .group {
    &__title {
      padding-top: 16px;
      color: #000;
      font-size: 24px;
      font-weight: 500;
    }
    &__applications {
      padding-top: 4px;
      
      &Wrapper {
        border-top: 0.5px solid #ECECEC;
        border-left: 0.5px solid #ECECEC;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        &.poor {
          border-top: none;
        }
      }
    }
  }

  margin-bottom: 50px;
}

@media (min-width: 1024px) {
  .container {
    .group {
      &__applicationsWrapper {
        grid-template-columns: repeat(3, 1fr);
      }
      &__title {
        padding-top: 32px;
      }
    }
  }
}