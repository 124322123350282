@import "../../variables";

.wrapper {
    overflow-x: auto;
    padding: 0 16px;
}

.container {
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
  padding: 8px 0;

  .item {
    cursor: pointer;
    color: #424242;
    font-size: 16px;
    font-weight: 500;
    padding: 8px 16px;
    white-space: nowrap;
    border-radius: 20px;
    background: #E8E8E8;
    transition: .2s ease all;

    &.active {
      background: $violet;
      color: #ffffff;
    }
  }

  .reset {
    min-width: 64px;
    display: flex;

    .item {
      width: max-content;
      display: flex;
      align-items: center;

      &:hover {
        background: #E8E8E8;
      }
    }

    img {
      width: 16px;
      height: 16px;
    }
  }
}

@media (min-width: 768px) {
  .wrapper {
    // padding: 0;
    overflow-x: visible;
  }
  .container {
    flex-wrap: wrap;

    .item:hover {
      background: $violet;
      color: #ffffff;
    }
  }
}

@media (min-width: 1024px) {
  .container {
    .item {
      &:hover {
        opacity: .8;
      }
    }
  }
}