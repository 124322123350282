.container {
  position: relative;

  .icon{
    cursor: pointer;
  }

  .menu {
    right: 0;
    margin-top: 4px;
    position: absolute;
    border-radius: 16px;
    padding: 16px 16px 24px;
    background: #FFF;
    opacity: 0;
    visibility: hidden;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 10px 23px 0px rgba(0, 0, 0, 0.10), 0px 41px 41px 0px rgba(0, 0, 0, 0.09), 0px 93px 56px 0px rgba(0, 0, 0, 0.05), 0px 165px 66px 0px rgba(0, 0, 0, 0.01), 0px 258px 72px 0px rgba(0, 0, 0, 0.00);
    transition: .2s ease all;

    &.opened {
      opacity: 1;
      z-index: 99;
      visibility: visible;
    }
  }
  .username {
    color: #000;
    font-size: 20px;
    margin-bottom: 26px;
    font-weight: 500;
  }
  .version {
    margin-top: 10px;
    color: #000;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    opacity: 0.6;
  }

  .button {
    min-width: 268px;
  }
}