.item {
  padding: 16px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  gap: 12px;
  border-bottom: 0.5px solid #ECECEC;
  border-right: 0.5px solid #ECECEC;

  &.borderTop {
    border-top: 0.5px solid #ECECEC;
  }

  .content > *:first-child {
   margin-bottom: 4px;
  }
  .title {
    color: #000;
    font-size: 19px;
    font-weight: 500;
  }
  .description {
    color: #000;
    word-break: break-all;
    font-size: 14px;
    font-weight: 500;
    opacity: .6;
  }
}

.modalBody {
  padding: 24px;
  box-sizing: border-box;
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  justify-content: center;

  .description {
    width: 100%;
    text-align: center;
    font-size: 14px;
    opacity: .6;
    font-weight: 500;
    color: #000;
  }
  .title {
    width: 100%;
    color: #000;
    text-align: center;
    font-size: 19px;
    font-weight: 500;
  }
}

.icon {
  flex-shrink: 0;
  border-radius: 20px;
  width: 74px;
  object-fit: contain;
  height: 74px;
  border-radius: 20px;
  background: #D9D9D9;
}


@media (min-width: 1024px) {
  .item {
    flex-direction: row;
  }
}