.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .7);
}

.Modal {
  box-sizing: border-box;
  position: absolute;
  top: auto;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 16px 16px 0px 0px;
  background: #FFF;
  padding: 16px 16px 36px;
  outline: none;

  .buttons {
    padding-top: 10px;

    & > *:first-child {
      margin-bottom: 10px;
    }

    .button {
      width: 100%;
    }
  }
}

@media (min-width: 768px) {
  .Modal {
    border-radius: 16px;
    left: 50%;
    top: 50%;
    width: 375px;
    right: auto;
    bottom: auto;
    padding: 16px 32px 36px;
    transform: translate(-50%, -50%);

    .buttons {
      padding: 0 8px;
      display: flex;
      gap: 10px;
      & > *:first-child {
        margin-bottom: 0;
      }
      .button {
        width: 50%;
      }
    }
  }
}