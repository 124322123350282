.wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  .container {
    padding: 0 16px 118px;
    box-sizing: border-box;
    width: 100%;
  }
}

.title {
  margin: 0 auto;
  color: #FFF;
  text-align: center;
  font-size: 31px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  max-width: 332px;
  padding: 24px 0;
}
.button {
  width: 100%;
}

@media (min-width: 768px) {
  .wrapper {
    justify-content: center;
  
    .container {
      width: auto;
      padding: 0 0 118px;
    }    
  }
  .button {
    width: 322px;
    margin: 0 auto;
  } 
}